export const CHECKOUT_SELECTED_PRICE_CONFIRMED =
  "CHECKOUT_SELECTED_PRICE_CONFIRMED";
export const CHECKOUT_FULL_NAME_CHANGED = "CHECKOUT_FULL_NAME_CHANGED";
export const CHECKOUT_SETUP_INTENT_CREATED = "CHECKOUT_SETUP_INTENT_CREATED";
export const CHECKOUT_SUBSCRIPTION_CREATED = "CHECKOUT_SUBSCRIPTION_CREATED";

export const HCP_PLACE_SELECTED = "HCP_PLACE_SELECTED";

export const COUPON_CODE_FAILURE = "COUPON_CODE_FAILURE";
export const COUPON_CODE_LOADING = "COUPON_CODE_LOADING";
export const COUPON_CODE_SUCCESS = "COUPON_CODE_SUCCESS";
export const COUPON_CODE_RESET = "COUPON_CODE_RESET";
export const COUPON_API_RESULTS = "COUPON_API_RESULTS";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_CLICKED = "LOGOUT_CLICKED";

export const SET_ANALYTICS_UUID = "SET_ANALYTICS_UUID";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const USER_EMAIL_CHANGED = "USER_EMAIL_CHANGED";

export const FIRST_NAME_CHANGED = "FIRST_NAME_CHANGED";

export const QUIZ_EMAIL_ENTERED = "QUIZ_EMAIL_ENTERED";

export const STRIPE_CUSTOMER_EMAIL_IN_USE = "STRIPE_CUSTOMER_EMAIL_IN_USE";

export const CLEAR_STRIPE_EMAIL_IN_USE = "CLEAR_STRIPE_EMAIL_IN_USE";

export const CLEAR_EXISTING_CUSTOMER_STRIPE_DETAILS =
  "CLEAR_EXISTING_CUSTOMER_STRIPE_DETAILS";

export const CLEAR_STRIPE_SUBSCRIPTION = "CLEAR_STRIPE_SUBSCRIPTION";

interface ClearStripeSubscription {
  type: typeof CLEAR_STRIPE_SUBSCRIPTION;
}

interface ClearExistingCustomerStripeDetails {
  type: typeof CLEAR_EXISTING_CUSTOMER_STRIPE_DETAILS;
}

interface StripeClearEmailInUse {
  type: typeof CLEAR_STRIPE_EMAIL_IN_USE;
}

interface StripeCustomerEmailUsed {
  type: typeof STRIPE_CUSTOMER_EMAIL_IN_USE;
  code: string;
}

interface FirstNameChanged {
  type: typeof FIRST_NAME_CHANGED;
  firstName: string;
}
interface QuizEmailEnteredAction {
  type: typeof QUIZ_EMAIL_ENTERED;
}

interface CouponCodeLoading {
  type: typeof COUPON_CODE_LOADING;
}

interface CouponCodeFailure {
  type: typeof COUPON_CODE_FAILURE;
}

interface CouponCodeReset {
  type: typeof COUPON_CODE_RESET;
}

interface CouponCodeSuccess {
  type: typeof COUPON_CODE_SUCCESS;
  percentOff: number;
  couponCode: string;
}

interface CouponApiResults {
  type: typeof COUPON_API_RESULTS;
  data: any;
}

interface SetAnalyticsUuidAction {
  type: typeof SET_ANALYTICS_UUID;
  data: string;
}

interface hcpPlaceSelected {
  type: typeof HCP_PLACE_SELECTED;
  place: string;
}
interface LogInSuccessAction {
  type: typeof LOGIN_SUCCESS;
  token: string;
}

interface LogOutClickedAction {
  type: typeof LOGOUT_CLICKED;
}

interface RegisterSuccessAction {
  type: typeof REGISTER_SUCCESS;
  token: string;
}

interface CheckoutSelectedPriceConfirmedAction {
  type: typeof CHECKOUT_SELECTED_PRICE_CONFIRMED;
  priceId: string;
  offering: string;
}
interface CheckoutFullNameChangedAction {
  type: typeof CHECKOUT_FULL_NAME_CHANGED;
  fullName: string;
}
interface CheckoutStripeIntentCreatedAction {
  type: typeof CHECKOUT_SETUP_INTENT_CREATED;
  stripeCustomerId: string;
  stripeClientSecret: string;
  setupIntentId: string;
}

interface CheckoutUpfrontSubscriptionCreated {
  type: typeof CHECKOUT_SUBSCRIPTION_CREATED;
  subscriptionId: string;
  stripeCustomerId: string;
  stripeClientSecret: string;
  setupIntentId?: string;
  paymentIntentId?: string;
}

interface UserEmailChangedAction {
  type: typeof USER_EMAIL_CHANGED;
  email: string;
}

export type AppAction =
  | CheckoutFullNameChangedAction
  | CheckoutSelectedPriceConfirmedAction
  | CheckoutStripeIntentCreatedAction
  | ClearExistingCustomerStripeDetails
  | ClearStripeSubscription
  | CouponCodeLoading
  | CouponCodeFailure
  | CouponCodeSuccess
  | CouponApiResults
  | CouponCodeReset
  | FirstNameChanged
  | LogInSuccessAction
  | LogOutClickedAction
  | QuizEmailEnteredAction
  | RegisterSuccessAction
  | SetAnalyticsUuidAction
  | UserEmailChangedAction
  | CheckoutUpfrontSubscriptionCreated
  | StripeCustomerEmailUsed
  | StripeClearEmailInUse
  | hcpPlaceSelected;
