import { AppAction, HCP_PLACE_SELECTED } from "../constants/actions";

import { produce } from "immer";

interface HcpSearchPlaceState {
  place?: string;
}

const initialState: HcpSearchPlaceState = {
  place: "",
};

export default (
  state: HcpSearchPlaceState = initialState,
  action: AppAction,
): HcpSearchPlaceState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case HCP_PLACE_SELECTED: {
        draft.place = action.place;
        break;
      }
    }
  });
};
