import { AnalyticsEventProperties, trackAnalytics } from "../utils/analytics";
import {
  GeeLocationMeta,
  GeoLocation,
} from "../custom-hooks/useFetchGeoLocation.ts";
import axios, { AxiosResponse } from "axios";

import { StripePrice } from "../models/stripe.ts";
import Utils from "../utils";
import { cioIdentifyRegisteredUser } from "./CustomerIO";
import http from "../utils/http";
import { logErr } from "../utils/logs";
import { noEmptyAnalyticProps } from "../utils/analytics";
import project from "../project";

const { apiBase, headerKey } = project;

export async function trackUserBiEvent(
  uuid: string,
  event: string,
  properties: Record<string, any>,
) {
  await axios.post(
    `${apiBase}/a/event`,
    {
      uuid,
      event,
      properties: noEmptyAnalyticProps(properties),
    },
    {
      headers: {
        "api-key": headerKey,
      },
    },
  );
}

export async function trackUserLeadEvent(
  userUuid: string,
  email: string,
  eventSource: string,
  consentProperties?: {
    emailConsent?: "yes" | "no";
    isGDPR?: "yes" | "no";
  },
) {
  await axios.post(
    `${apiBase}/a/lead`,
    { userUuid, email, eventSource, ...consentProperties },
    { headers: { "api-key": headerKey } },
  );
}

export async function trackInitiateCheckout(
  userUuid: string,
  email: string,
  selectedPrice?: StripePrice,
  eventDedupeId?: string,
) {
  await axios.post(
    `${apiBase}/a/checkout/init`,
    {
      userUuid,
      email,
      eventId: eventDedupeId,
      ...(selectedPrice && {
        currency: selectedPrice.currency,
        price: selectedPrice.unit_amount / 100,
      }),
    },
    { headers: { "api-key": headerKey } },
  );
}

export async function updateAnalyticsUserInfo(
  analyticsUUID: string,
  args: Partial<{
    adMetrics: Record<string, string>;
    userProperties: Record<string, string | number | boolean | null>;
    location: GeoLocation & GeeLocationMeta;
    googleClientId: string;
  }>,
) {
  const { adMetrics, googleClientId, location, userProperties } = args;

  // change currency property name here - avoids naming issues downstream
  if (location) {
    location.currencyCode = location.currency;
    delete location.currency;
  }

  if (analyticsUUID == null) {
    console.log("no analyticsUUID");
    return;
  }

  // ip address and user-agent will be included in headers automatically.
  await axios.post(
    `${apiBase}/a/user-info/${analyticsUUID}`,
    { adMetrics, userProperties, googleClientId, location },
    {
      headers: {
        "api-key": headerKey,
      },
    },
  );
}

export async function updateAnalyticsUserMonetizationTestFlag(
  analyticsUUID: string,
  mtfData: Record<string, any>,
) {
  if (analyticsUUID == null) {
    console.log("no analyticsUUID");
    return;
  }

  // ip address and user-agent will be included in headers automatically.
  await axios.post(
    `${apiBase}/a/user-mtf-info/${analyticsUUID}`,
    { monetizationTestFlags: mtfData },
    {
      headers: {
        "api-key": headerKey,
      },
    },
  );
}

export async function checkPaypalFastCheckoutStatus(stripeCustomer: string) {
  const body = { stripeCustomer };
  try {
    const response = await axios.post(
      `${apiBase}/stripe-webhook/setup-intent-status`,
      body,
    );
    return response;
  } catch (e) {
    console.log("Error checking paypal fast checkout status", e);
  }
}

export async function createLiveEventURL(eventId: string, uuid: string) {
  const body = { eventId, uuid };
  const response = await axios.post(
    `${apiBase}/live-event-url/create-link`,
    body,
  );
  return response;
}

export async function scheduleSharedResultsEmail(
  shareHash: string,
  uuid: string,
  email: string,
) {
  const body = { email, uuid, shareHash };
  const response = await axios.post(`${apiBase}/share/email`, body);
  return response;
}

export async function sharedResults(shareHash: string, uuid: string) {
  const response = await axios.get(
    `${apiBase}/share/data/${shareHash}?uuid=${uuid}`,
  );
  return response;
}

export async function liveEventLinkData(linkHash: string) {
  const response = await axios.get(
    `${apiBase}/live-event-url/data/${linkHash}`,
  );
  return response;
}

export async function createShareLink(
  calculations: any,
  uuid: string,
  shareType: "email" | "share",
): Promise<any> {
  const body = { calculations, uuid, shareType };
  const response = await axios.post(`${apiBase}/share/results/create`, body);
  return response;
}

export function fetchStripeIntent(
  paymentIntentId: string,
): Promise<AxiosResponse> {
  return http().get(`/register/stripe/intent/payment/${paymentIntentId}`);
}

export function createStripeSetupIntent(
  email?: string,
  userUuid?: string,
  isWinbackFromApp?: boolean,
): Promise<AxiosResponse> {
  const url = "/register/stripe/intent/card";

  const body = { email, userUuid, isWinbackFromApp };

  return http().post(url, body);
}

export function createExistingUserStripeSetupIntent(): Promise<AxiosResponse> {
  const url = "/stripe-subscription/payment-method-intent";
  return http().get(url);
}

export function fetchOrCreateStripeCustomer(
  email: string,
  setupIntentId?: string,
  paymentIntentId?: string,
): Promise<AxiosResponse> {
  const url = "/register/stripe/intent/customers";

  const body = { email, setupIntentId, paymentIntentId };

  return http().post(url, body);
}

/** This method requests a stripe customer ID for the currently logged in user */
export function fetchOrCreateStripeCustomerRegistered(
  email?: string,
  setupIntentId?: string,
): Promise<AxiosResponse> {
  const url = "/stripe-subscription/customer";

  const body = { email, setupIntentId }; // will use account email if left blank

  return http().post(url, body);
}

export function createNewStripeSubscription( // may be upfront or non-upfront
  email: string,
  priceId: string,
  trialDays?: number,
  currency?: string,
  userUuid?: string,
  countryCode?: string,
  postalCode?: string,
): Promise<AxiosResponse> {
  const url = "/register/stripe/intent/new-stripe-subscription";

  const body = {
    email,
    priceId,
    trialDays,
    currency,
    userUuid,
    countryCode,
    postalCode,
  };

  return http().post(url, body);
}

export async function e2eCleanup(email: string) {
  const body = {
    testUserEmail: email,
  };

  await axios.post(`${apiBase}/e2e/cleanup`, body);
}

export interface RegisterProps {
  email: string;
  firstName?: string;
  password?: string;
  stripeCustomerId?: string;
  priceId?: string;
  coupon?: string;
  fromWinback?: boolean;
}

export function registerUser(
  registerProps: RegisterProps,
  successCallback: (result: {
    token: string;
    subscriptionId?: string;
    predictedLtv: number;
    uuid: string;
  }) => void,
  errorCallback: (err: any) => void,
  useUpfrontPayment?: boolean,
) {
  const anonUUID = Utils.anonymousUuidStorage.get();

  // Get paymentIntentClientSecret from URL
  const url = new URL(window.location.href);
  // Keeping the old query param names for backwards compatibility
  // Will be phased out in the future
  const paymentIntentClientSecret =
    url.searchParams.get("payment_intent_client_secret") ||
    url.searchParams.get("paymentIntentClientSecret");
  const stripeSubscriptionId = url.searchParams.get("stripeSubscriptionId");

  const body = {
    ...registerProps,
    uuid: anonUUID,
    cioPushEnabled: true,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    enumVersion: 2,
    paymentIntentClientSecret,
    stripeSubscriptionId,
  };

  const headers = {
    "api-key": headerKey,
  };

  const endpoint = useUpfrontPayment ? "register/upfront" : "register/savvy";

  axios
    .post(`${apiBase}/${endpoint}`, body, { headers })
    .then(({ data: { predictedLtv, subscriptionId, token, userData } }) => {
      Utils.tokenStorage.store(token);

      cioIdentifyRegisteredUser(userData.uuid, body.email);
      const trackingProperties: AnalyticsEventProperties = {
        registerSuccess: {
          method: "email",
          organizationReferralCode: null,
        },
      };
      trackAnalytics("RegisterSuccess", "apiResponse", trackingProperties);
      successCallback({
        token,
        subscriptionId,
        predictedLtv,
        uuid: userData.uuid,
      });
    })
    .catch((err) => {
      logErr(err);
      trackAnalytics("RegisterFailed", "apiResponse");
      errorCallback(err);
      throw err;
    });
}

export async function patchUser(
  patches: {
    email?: string;
    password?: string;
    firstName?: string;
    quiz_scores?: string;
    quizAnswers?: string;
  },
  callback: (err?: any) => void,
) {
  await http()
    .patch(`${apiBase}/user`, patches)
    .then(() => callback())
    .catch((err) => callback(err));
}

export async function getMagicLink() {
  return await http()
    .post(`${apiBase}/auth/magic-link`)
    .then((r) => r)
    .catch(() => {
      console.error("Error requesting link");
      return null;
    });
}

export type StripeUsageSources = "checkout" | "account" | "subscribe";

interface StripeCardError {
  confirmationType: "confirmPayment" | "confirmSetup";
  userUuid: string;
  email: string;
  eventSource: StripeUsageSources;
  message?: string;
  code?: string;
  declineCode?: string;
  paymentMethodId?: string;
  setupIntentId?: string;
  paymentIntentId?: string;
  paymentMethod?: string;
  cardType?: string;
  attempts?: number;
}

export async function reportStripeError({
  attempts,
  cardType,
  code,
  confirmationType,
  declineCode,
  email,
  eventSource,
  message,
  paymentIntentId,
  paymentMethod,
  paymentMethodId,
  setupIntentId,
  userUuid,
}: StripeCardError) {
  try {
    await axios.post(
      `${apiBase}/stripe-error/card-error`,
      {
        confirmationType,
        userUuid,
        email,
        code,
        message,
        declineCode,
        paymentMethodId,
        setupIntentId,
        paymentIntentId,
        paymentMethod,
        cardType,
        attempts,
        eventSource,
      },
      { headers: { "api-key": headerKey } },
    );
  } catch (e) {
    console.error("card-error failed", e);
  }
}
