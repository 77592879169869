// please add newest flags at the top of the list

export const flagIds = [
  "long-results-page",
  "fast-checkout",
  "trial-anxieties",
  "3-billing-rollout",
  "loading-program",
  "price-framing",
  "auto-tax-enabled",
  "show-tax-on-checkouts",
  "trial-reminders-toggle",
  "payment-location-mode",
  "hcp-details",
  "hcp-details-search",
  "diagnosis-question-lp",
  "trial-cancelation-flow",
  "magic-deep-link",
  "tailored-value-prop",
  "personal-story",
  "user-insight-popup",
  "paywall-before-results",
  "dedupe-account-funnel-changes",
  "apple-pay-modal",
  "upfront-payments",
  "share-results",
  "localized-currency",
  "paypal-payments",
  "program-pricing",
  "show-quiz-error",
  "illustration-question-lp",
  "emoji-question-lp",
  "growthbook-aa-test",
  "growthbook-aa-test-continue",
] as const;

// monetization test setup
export const monetizationTestFlags: Record<
  string,
  Record<string, string | number | null>
> = {
  "3-billing-rollout": {
    monetization_flag: "Yes",
    monetization_experiment_name: "3-billing-rollout",
    monetization_test_id: 1,
    monetization_test_variant: "$VARIANT",
  },
  "default-no-test": {
    monetization_flag: "No",
    monetization_experiment_name: null,
    monetization_test_id: null,
    monetization_test_variant: null,
  },
};

// DevMenu only
// if a flag is NOT in this list, variant will display as a text input
export const flagVariants: Record<string, string[]> = {
  "long-results-page": ["control", "long-results-page"],
  "fast-checkout": ["control", "fast-checkout"],
  "trial-anxieties": ["control", "trial-anxieties", "shorter-trial-anxieties"],
  "3-billing-rollout": [
    "control",
    "3-billing-rollout-original",
    "3-billing-rollout-3emphasis",
  ],
  "price-framing": ["control", "price-framing"],
  "loading-program": ["control", "loading-program"],
  "trial-reminders-toggle": ["control", "trial-reminders-toggle"],
  "show-tax-on-checkouts": ["control", "inclusive", "exclusive"],
  "payment-location-mode": ["default", "user", "auto"],
  "hcp-details": ["control", "hcp-details"],
  "hcp-details-search": ["control", "hcp-details-search"],
  "diagnosis-question-lp": [
    "control",
    "diagnosis-question-lp",
    "diagnosis-question-review-lp",
    "diagnosis-question-trust-lp",
  ],
  "trial-cancelation-flow": ["control", "trial-cancelation-flow"],
  "tailored-value-prop": ["control", "tailored-value-prop"],
  "user-insight-popup": [
    "control",
    "price-options",
    "result-adhd-calculations",
  ],
  "paywall-before-results": ["control", "paywall-before-results"],
  "apple-pay-modal": [],
  "upfront-payments": ["control", "upfront-v3-2options", "upfront-v3-3options"],
  "share-results": [],
  "localized-currency": ["control", "localized"],
  "paypal-payments": [],
  "program-pricing": [],
  "show-quiz-error": [],
  "illustration-question-lp": [
    "control",
    "productive-question-lp",
    "​priority-question-lp",
  ],
  "emoji-question-lp": ["control", "feeling-question-lp", "putoff-question-lp"],
  "personal-story": ["control", "personal-story"],
  "growthbook-aa-test": ["control", "growthbook-aa-test"],
  "growthbook-aa-test-continue": ["control", "growthbook-aa-test-continue"],
  "magic-deep-link": ["control", "magic-deep-link"],
} as const;

export type FlagId = (typeof flagIds)[number];
